#map {
  position: relative;
  display: flex;
  width: 100vw;
  height: 80vh;
}
#map > iframe {
  position: relative;
  box-sizing: border-box;
}
#map > .infos {
  margin-top: 10%;
  margin-left: 10%;
  position: absolute;
  padding: 40px 70px;
  color: #FFF;
  background-color: #501075;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  z-index: 10;
}
#map > .infos > .header {
  display: flex;
  font-size: 1.2em;
  color: yellow;
}
#map > .infos > .body > p {
  margin: 10px 0px;
  font-weight: 200;
}
@media screen and (max-width: 1199px) {
  #map > .infos {
    padding: 20px 50px;
  }
  #map > .infos > .header {
    font-size: 1em;
  }
  #map > .infos > .body > p {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 991px) {
  #map > .infos {
    padding: 15px 30px;
    margin-top: 0%;
  }
  #map > .infos > .header {
    font-size: 1em;
  }
  #map > .infos > .body > p {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 767px) {
  #map > iframe {
    height: 70vh;
  }
  #map > .infos > .header {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 479px) {
  #map > .infos {
    margin: 40px 20px;
    width: 90%;
  }
}
@media screen and (max-width: 350px) {
  #map > .infos > .header > svg {
    margin-top: 10px;
  }
}
