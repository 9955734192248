#services {
  width: 1400px;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  /* .modal{
        max-width: 80vw;
        max-height: 180px !important;
        overflow-y: auto;
    } */
}
#services .first-content {
  width: 50%;
}
#services .first {
  width: 100%;
  /* backg    round: #27043A; */
  /* background: linear-gradient(180deg, #27043A 0%, #681599 100%); */
  display: flex;
  padding: 96px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-self: stretch;
}
#services .first .list-services {
  font-size: 18px;
  justify-content: start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(199, 210, 254, 0.5);
  padding: 20px;
  box-sizing: border-box;
}
#services .first .list-services > button {
  display: flex !important;
  width: 50%;
  padding: 5px;
  border: solid 2px transparent;
  border-radius: 15px;
}
#services .first .list-services > button > svg {
  display: flex;
  margin-right: 10px;
  color: #A5B4FC;
  font-size: 24px;
}
#services .first .list-services > button:hover {
  border-color: #A5B4FC;
}
#services .first .content-buttons {
  width: 100% !important;
}
#services .first .content-buttons > a {
  display: block !important;
  width: 100% !important;
}
#services .first .content-buttons > a > button {
  width: 100% !important;
}
#services .second {
  width: 50%;
  display: flex;
  max-width: 700px;
  padding: 40px 60px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
}
#services .second > div > span {
  background: linear-gradient(180deg, #FCC311 0%, #D88C02 100%);
  width: 48px !important;
  height: 48px !important;
  display: flex;
}
#services .second > div > span > img {
  width: 100%;
  height: 100%;
  color: white;
}
#services .mobile {
  visibility: hidden;
}
@media (max-width: 966px) {
  #services {
    /* background-color: #D88C02; */
  }
  #services .mobile {
    visibility: visible;
  }
  #services > .first-content {
    width: 100%;
    padding: 40px 0px;
  }
  #services > .first-content .first {
    padding: 20px;
  }
  #services > .first-content .list-services {
    font-size: 18px;
    display: block;
    padding: 10px;
  }
  #services > .first-content .list-services > button {
    width: 100%;
  }
  #services > .second {
    width: 0px;
    height: 0px;
    padding: 0px;
    visibility: hidden;
  }
}
#modal-services {
  max-height: 75vh;
  padding: 10px;
  position: absolute;
  margin-top: -30px;
  max-width: 70vw;
  overflow-y: auto;
}
#modal-services > div > span {
  background-color: #27043A;
}
#modal-services .span-modal {
  background: linear-gradient(180deg, #FCC311 0%, #D88C02 100%) !important;
  width: 48px !important;
  height: 48px !important;
  display: flex;
}
#modal-services .span-modal img {
  width: 100%;
  height: 100%;
  color: white;
}
