.card {
    margin: 15px 20px;
    display: flex;
    height: 420px;
    flex-direction: column;
    justify-content: center;
    padding: 45px 0;
    background-color: #fff; /* Cor de fundo do card */
    color: #fff; /* Cor de fundo do card */
    overflow: hidden;
    position: relative;

    .first-card{
        color: #501075 !important;
        padding: 0px !important;
        display: flex !important;
        height: 100% !important;
        justify-content: center !important;

        >h3{
          color: #501075;
          font-size: 20px;
        }

      >h2 {
          border-left: 1px solid #501075;
          padding: 0 0 0px 10px;
          font-size: 35px !important;
          font-weight: 700;
          margin-bottom: 10px;
          text-align: start !important;
          margin-left: 5px;
      }
    }

    .card-content {
      width: 100%;
      height: 100%;
      position: relative ;
      z-index: 10;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      >h2{
        font-weight: 700;
        text-align: center;
        font-size: 28px;
        text-transform: uppercase;
      }
    }

    .card-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      filter: brightness(0.7);
    }
}

@media screen and (max-width: 991px) {
  .card {
    margin: 10px;
    height: 300px;
    padding: 30px 0;

    .first-card{

        >h3{
          font-size: 18px;
        }

      >h2 {
          font-size: 25px !important;
          font-weight: 700;
      }
    }

    .card-content {
      padding: 10px;
      flex-direction: column;
      justify-content: space-between;

      >h2{
        font-size: 18px;
      }
    }
 }
}
/*@media screen and (max-width: 767px) {
}
@media screen and (max-width: 479px) {
}
@media screen and (max-width: 350px) {
} */
