*{
    margin: 0;
    padding: 0;
}

html > body{
    padding: 0px !important;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
}

body > #root > .App > section > main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}