body > #root nav {
  position: fixed;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 99;
  display: flex;
}
body > #root nav > .container-menu {
  margin: auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}
body > #root nav > .container-menu > .menu_toggle {
  align-self: center;
  display: none;
}
body > #root nav > .container-menu > .logo {
  z-index: 100;
}
body > #root nav > .container-menu > .logo img {
  width: 100px;
  padding: 10px;
}
body > #root nav > .container-menu > .links {
  display: flex;
  align-items: center;
  right: 0;
}
body > #root nav > .container-menu > .links > a,
body > #root nav > .container-menu > .links div > span {
  position: relative;
  margin-left: 40px;
  margin-bottom: 0px;
  padding: 5px 8px;
  font-weight: 500;
}
body > #root nav > .container-menu > .links > .itens_menu span:hover,
body > #root nav > .container-menu > .links div > span:hover {
  border-bottom: 1px solid yellow;
}
@media screen and (max-width: 1439px) {
  body > #root nav .container-menu {
    width: 80vw;
  }
}
@media screen and (max-width: 1199px) {
  body > #root nav .container-menu > .logo img {
    width: 100px;
  }
  body > #root nav .container-menu .links > .button-blue {
    height: 35px;
    width: 160px;
    margin-top: 7px;
  }
  body > #root nav .container-menu .links > a {
    margin-left: 30px;
  }
}
@media screen and (max-width: 991px) {
  body > #root nav .container-menu > .logo {
    margin: 5px 0px;
  }
  body > #root nav .container-menu > .logo img {
    width: 100px;
  }
  body > #root nav .container-menu > .links {
    display: none;
  }
  body > #root nav .container-menu > .links a {
    margin: 0px;
  }
  body > #root nav .container-menu > .links > div > #solution {
    display: none !important;
  }
  body > #root nav .container-menu > .menu_toggle {
    width: 40px;
    margin-right: 10px;
    display: block;
    left: 20px;
    z-index: 100;
  }
  body > #root nav .container-menu > .menu_toggle > .one,
  body > #root nav .container-menu > .menu_toggle .two,
  body > #root nav .container-menu > .menu_toggle .three {
    background-color: #606060;
    height: 5px;
    width: 40px;
    margin: 6px auto;
    transition-duration: 0.3s;
  }
  body > #root nav .container-menu > .on {
    margin: 0px;
    padding: 0px;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: #681599;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  body > #root nav .container-menu > .on a,
  body > #root nav .container-menu > .on div > span {
    color: #fff !important;
    font-size: 2em;
    margin-left: 0px;
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  body > #root nav > .container-menu {
    width: 100vw;
  }
  body > #root nav > .container-menu > .links {
    display: none;
  }
  body > #root nav > .container-menu > .links a {
    margin: 0px;
  }
  body > #root nav > .container-menu > .links > div > #solution {
    display: none !important;
  }
  body > #root nav > .container-menu > .on {
    display: block;
    padding-top: 20vh;
  }
  body > #root nav > .container-menu > .on a,
  body > #root nav > .container-menu > .on div > span {
    font-size: 2em;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
}
