/* Font Family */
/* Borda arredondada */
section.introduction{
  width: 100%;

  /* background: linear-gradient(180deg, #27043A 0%, #681599 100%); */
  /* background: linear-gradient(180deg, #FCC311 0%, #D88C02 100%); */
}

#introduction {
    width: 100%;
    min-height: 90vh;
    height: auto;
    background-image: url('../../assets/logos/logo-multicolor.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 107% center;
    display: flex;
  }
  #introduction .container {
    align-self: center ;
    width: 1200px;
    margin: 40px auto;

    color: #000;
  }
  #introduction .container h1 {
    color: #501075;
    width: 50%;
    line-height: 1.1em;
    margin-bottom: 40px;
  }
  #introduction .container > p {
    width: 40%;
    color: #501075;
    font-weight: 300;
    font-size: 1.2em;
    margin-top: 15px;
  }
  @media screen and (max-width: 1439px) {
    #introduction .container {
      padding-top: 50px;
      padding-bottom: 50px;
      width: 80vw;
    }
    #introduction .container h1 {
      width: 65%;
    }
    #introduction .container > p {
      width: 55%;
      font-size: 1.2em;
    }
  }
  @media screen and (max-width: 1199px) {
    #introduction .container h1 {
      margin-bottom: 40px;
    }
    #introduction .container > p {
      width: 50%;
      font-size: 1em;
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 991px) {
    #introduction {
      background-size: 50vh;
      background-position: 115% center;
    }
    #introduction .container h1 {
      margin-bottom: 30px;
    }
    #introduction .container > p {
      font-size: 1.3em;
    }
  }
  @media screen and (max-width: 767px) {
    #introduction {
      background-position: center 50px;
      background-size: 150px;
      /* min-height: 85vh; */
      /* height: 85vh; */
    }
    #introduction > .container {
      margin-top: 90px;
      padding-top: 100px;
      width: 50%;
    }
    #introduction > .container h1 {
      width: 100%;
      margin-bottom: 30px;
    }
    #introduction > .container > p {
      width: 100%;
      font-size: 1em;
    }
  }
  @media screen and (max-width: 479px) {
    #introduction {
      background-size: 80px;
      min-height: auto;
      /* height: auto;
      max-height: 130vh; */
    }
    #introduction > .container {
      margin-top: 40px;
      padding-top: 130px;
      width: 80%;
    }
  }
  @media screen and (max-width: 350px) {
    #introduction .container {
      padding-top: 150px;
      width: 90%;
    }
  }
  