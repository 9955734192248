.wpp-container {
  width: 1400px;
  max-width: 100%;
  margin: 40px 0;
}
.wpp-container > div {
  padding: 0px 30px;
  color: #501075;
  width: 55%;
}
.wpp-container > div h1 {
  width: 90%;
}
.wpp-container > div > p {
  width: 70%;
}
.wpp-container > form {
  padding: 0px 30px;
  width: 45%;
}
@media screen and (max-width: 1199px) {
  .wpp-container {
    width: 100%;
  }
  .wpp-container > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px;
  }
  .wpp-container > div h1 {
    text-align: center;
    width: 90%;
    margin: auto;
  }
  .wpp-container > div > p {
    text-align: center;
    margin: auto;
    width: 80%;
  }
  .wpp-container > form {
    width: 90%;
    padding: 0px 20px;
    margin: auto;
  }
}
