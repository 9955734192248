

#content-gallery{
    width: 95%;
    /* margin: 100px 0; */
    color: #501075;

    #cards-container{
        margin: auto;
        width: 80%;
        margin-top: 100px;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    #content-carousel{
        >h1:first-letter { text-transform: capitalize; }
        >h1{ text-transform: lowercase; }

        > div > button{
            background-color: rgba(173, 173, 173, 0.596)!important;
        }
    
    }

    .content-images{
        height: 400px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        >div{
            margin: 0 16px;

        }
    }

    
}

@media screen and (max-width: 991px) {
    #content-gallery{
        margin: 20px 0;

        #cards-container{
            margin: auto;
            width: 100%;
            margin-top: 50px;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .content-images{
            height: 250px;
            grid-template-columns: 1fr 1fr;

            img{
                height: 250px;
            }
        }


    }
  }
  @media screen and (max-width: 767px) {
  }
  @media screen and (max-width: 479px) {
  }
  @media screen and (max-width: 350px) {
  }